import _ from 'lodash';
const { uniqBy } = _;

export default class Cart {
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    getProductVariants() {
        const productVariants = this.items.map(
            (cartItem) => cartItem.productVariant,
        );

        return uniqBy(productVariants, 'id');
    }

    getCartItemsForProductVariant(productVariant) {
        return this.items.filter(
            (cartItem) => cartItem?.productVariant?.id === productVariant.id,
        );
    }

    getItemCount() {
        return this.items.reduce(
            (total, cartItem) => total + cartItem.quantity,
            0,
        );
    }

    getWeightForProductVariant(productVariant) {
        const cartItems = this.getCartItemsForProductVariant(productVariant);

        return cartItems.reduce(
            (total, cartItem) => total + cartItem.weightInMajorUnits,
            0,
        );
    }

    getDiscountForProductVariant(productVariant) {
        return this.discounts.find(
            (discount) => discount.productVariantId === productVariant.id,
        );
    }
}
